import React from 'react'
import CommonFooter from '../components/CommonFooter'
import Hero from '../components/Screen3/Hero'
import Expertise from '../components/Screen3/Expertise'
import Success from '../components/Screen3/Success'
import Whoweare from "../components/Screen3/Whoweare"
import Header from '../components/Header'

const Screen3 = () => {
  return (<div style={{fontFamily:"Gilroy, sans-serif"}}  className='overflow-x-hidden'>
    <Header/>
    <Hero/>
    <Expertise/>
    <Success/>
    <Whoweare/>
    <CommonFooter/>
  </div>)
}

export default Screen3