import React from "react";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import { Home, Screen2, Screen3, Screen4, Screen5 } from "./pages";
import Contact from "./components/Home/Contact";

const App = () => {
  const router = createBrowserRouter([
    {
      path: "/",
      element: <Home />,
    },
    {
      path: "/screen2",
      element: <Screen2 />,
    },
    {
      path: "/screen3",
      element: <Screen3 />,
    },
    {
      path: "/screen4",
      element: <Screen4 />,
    },
    {
      path: "/screen5",
      element: <Screen5 />,
    },
    {
      path: "/contact",
      element: <Contact />,
    }
  ]);
  return <RouterProvider router={router} />;
};

export default App;
