import React from "react";
import nasaimg from "../../assets/nasa-Q1p7bh3SHj8-unsplash.png";

const Whoweare = () => {
  return (
    <section className="min-h-screen relative py-20 pb-32 lg:pb-52 text-[#fcfcfc] bg-black w-full overflow-hidden flex flex-col items-center">
      <img
        src={nasaimg}
        alt=""
        className="absolute bottom-0 left-0 right-0 object-cover"
      />
      <p className="text-3xl font-semibold md:text-[65px] md:leading-[60px] text-center z-10 mt-16">
        Who We Are
      </p>
      <p className="max-w-[80%] md:text-[24px] md:leading-[30px] text-center z-10 font-medium mt-8 lg:w-[60%]">
        Our values shape the culture of our organization and define who we are.
        These are at the core of how we work and what we do. We are:
      </p>
      <div className="flex justify-around items-center gap-20 flex-wrap z-10 mt-24 w-[96%]">
        <div className="flex justify-center items-center lg:border-r flex-col gap-6 w-full md:w-[40%] lg:w-[28%] px-8">
          <p className="text-[22px] leading-[24px] font-semibold text-center">
            Your Next-Gen Technology Partner
          </p>
          <p className="text-[20px] leading-[24px] font-normal text-center">
            We provide industry expertise and solution IPs to help customers
            achieve successful business outcomes.
          </p>
        </div>
        <div className="flex justify-center items-center flex-col gap-6 w-full md:w-[40%] lg:w-[28%] px-8 lg:border-r">
          <p className="text-[22px] leading-[24px] font-semibold text-center">
            Team-Oriented
          </p>
          <p className="text-[20px] leading-[24px] font-normal text-center">
            We work together to drive change by setting the bar for future
            technologies and our way of working.
          </p>
        </div>
        <div className="flex justify-center items-center flex-col gap-6 w-full md:w-[40%] lg:w-[28%] px-8">
          <p className="text-[22px] leading-[24px] font-semibold text-center">
            Client-Focused
          </p>
          <p className="text-[20px] leading-[24px] font-normal text-center">
            We are, above all else, customer-centric. We are in it for the long
            run and have an unwavering passion for client success.
          </p>
        </div>
      </div>
    </section>
  );
};

export default Whoweare;
