import React from "react";
import sideImg from "../../assets/brian-mcgowan-67NfRadTD0g-unsplash.png";

const Hero = () => {
  return (
    <section className="bg-[#000000] text-white flex justify-center items-center overflow-hidden min-h-screen w-full relative">
      <img src={sideImg} alt="" className="absolute bottom-0 right-0 lg:block lg:h-screen" />
      <div className="lg:absolute static z-10 max-w-[90%] md:max-w-[60%] flex flex-col gap-2 lg:left-24 lg:top-[40vh] lg:max-w-[35%]">
        <p className="font-semibold md:text-[60px]">About Us</p>
        <p className="text-sm md:text-lg ">
          Passionate about exceptional IT services, delivering innovative
          solutions to meet unique needs.
        </p>
        <button className="w-max px-3 py-1.5 bg-black text-white border border-white shadow-[2px_2px_0px_0px] shadow-purple-600 rounded-full text-center">
          Read more
        </button>
      </div>
    </section>
  );
};

export default Hero;
