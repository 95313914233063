import React from 'react'
import Hero from '../components/Screen5/Hero'
import CommonFooter from "../components/CommonFooter"
import Whatwedo from '../components/Screen5/Whatwedo'
import Header from '../components/Header'

const Screen5 = () => {
  return (<div style={{fontFamily:"Gilroy, sans-serif"}}  className='overflow-x-hidden'>
    <Header/>
    <Hero/>
    <Whatwedo/>
    <CommonFooter/>
  </div>)
}

export default Screen5