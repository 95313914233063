import React from "react";
import sideImg from "../../assets/image 26.png";

const Hero = () => {
  return (
    <section className="bg-[#000000] text-white flex justify-center lg:justify-start items-center overflow-hidden min-h-screen w-full relative">
      <img src={sideImg} alt="" className="absolute bottom-0 right-0 object-cover object-center md:object-left h-full w-full" />
      <div className="static z-10 max-w-[90%] md:max-w-[60%] flex flex-col items-start gap-6 md:gap-10 lg:gap-16 lg:ml-20 lg:max-w-[45%]">
        <p className="font-semibold text-3xl md:text-[70px] md:leading-[84.63px] text-[#ECECEC]">Empowering Your Financial Future</p>
        <p className="text-sm md:text-lg">
        Banking, Financial Services, and Insurance Solutions for<br className="hidden lg:block" />Every Stage of Life
        </p>
        <button className="w-max px-3 py-1.5 bg-white text-black border border-black shadow-[2px_2px_0px_0px] shadow-purple-600 rounded-full text-center">
          Lets Talk
        </button>
      </div>
    </section>
  );
};

export default Hero;
