import React from 'react'
import pantera from "../../assets/Pantera Capital - png 0.png"
import pantera2 from "../../assets/Pantera Capital - png 1.png"
import reddit from "../../assets/Reddit - png 0.png"
import tcs from "../../assets/Tata Consultancy Services - png 0.png"
import chorus from "../../assets/Chorus.ai - png 0.png"
import breakerLine from "../../assets/Line 315.png"

const PerformaceAndData = () => {
  return (<section className='overflow-hidden min-h-screen bg-transparent backdrop-blur-lg w-full py-20 flex flex-col items-center gap-36'>
    <div className='flex relative justify-around min-h-[174px] items-end max-w-[90vw]'>
        <p className='absolute top-0 left-0'>our partners</p>
        <div className="flex justify-around items-center pt-16 gap-8 flex-wrap">
            <img src={pantera} alt="" />
            <img src={reddit} alt="" />
            <img src={tcs} alt="" />
            <img src={chorus} alt="" />
            <img src={pantera2} alt="" />
        </div>
    </div>
    <div className='flex justify-around items-center gap-16 pb-16 flex-wrap'>
        <div className="flex flex-col gap-3">
            <p className='text-[80px] leading-[96px] font-bold text-center'>75,000+</p>
            <p className='text-[28px] leading-[33.4px] font-semibold text-center'>Startups & Service <br /> Providers</p>
        </div>
        <img src={breakerLine} alt="" className='hidden lg:block' />
        <div className="flex flex-col gap-3">
            <p className='text-[80px] leading-[96px] font-bold text-center'>1,500+</p>
            <p className='text-[28px] leading-[33.4px] font-semibold text-center'>Institutional <br />Investors</p>
        </div>
        <img src={breakerLine} alt="" className='hidden lg:block' />
        <div className="flex flex-col gap-3">
            <p className='text-[80px] leading-[96px] font-bold text-center'>9,000+</p>
            <p className='text-[28px] leading-[33.4px] font-semibold text-center'>Angel <br /> Investors</p>
        </div>
    </div>
  </section>)
}

export default PerformaceAndData