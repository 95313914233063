import React from 'react'
import vector from "../../assets/Vector.png"
import iphone from "../../assets/iphoneTransparent-08099e9d.png"
import iphonebg from "../../assets/Rectangle 2669.png"

const HomeServices = () => {
  return (<div style={{backgroundImage:`url(${vector})`}} className='w-full h-full py-12'>
    <div className="flex lg:justify-between flex-col lg:flex-row justify-center lg:px-40 items-center gap-12 flex-wrap">
        <div className='flex flex-col justify-center items-start gap-8'>
        <p className="text-[40px] leading-10 font-bold text-black">let us build it <br /> for you.</p>
        <p className="text-lg font-semibold text-black">providing you industry level <br /> solutions to cater your needs and <br /> help you become the next big <br /> thing.</p>
        <button className="px-3 py-1.5 bg-white text-black border border-black shadow-[2px_2px_0px_0px] shadow-purple-600 rounded-full text-center">
              get started
            </button>
        </div>
        <div className='relative w-max h-max'>
            <img src={iphone} className='absolute top-0 h-[calc(100vh-4rem)] z-10' alt="" />
            <div className='relative z-0 h-max w-max'>
                <img src={iphonebg} alt="" className="h-[calc(100vh-4rem)] w-full top-0" />
                <div className="absolute inset-16 md:inset-20 md:mt-0 mt-6 lg:inset-12 lg:mt-4 flex flex-col gap-y-8 items-start justify-start">
                <p className="text-[50px] leading-10 md:text-[140px] md:leading-[120px] lg:text-[45px] text-white font-bold lg:leading-6">app</p>
                <p className="text-[50px] leading-10 md:text-[140px] md:leading-[120px] lg:text-[45px] text-white font-bold lg:leading-6">dev</p>
                <p className="text-[50px] leading-10 md:text-[140px] md:leading-[120px] lg:text-[45px] text-[#F77179] font-bold lg:leading-6">elo</p>
                <p className="text-[50px] leading-10 md:text-[140px] md:leading-[120px] lg:text-[45px] text-[#F77179] font-bold lg:leading-6">pm</p>
                <p className="text-[50px] leading-10 md:text-[140px] md:leading-[120px] lg:text-[45px] text-[#F77179] font-bold lg:leading-6">ent</p>
                <p className='text-white text-lg md:text-2xl lg:text-base font-semibold text-start'>creating standalone products to serve the users better</p>
                </div>
            </div>
        </div>
    </div>
  </div>)
}

export default HomeServices