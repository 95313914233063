import React from 'react'
import Homehero from '../components/Home/Homehero'
import HomeServices from '../components/Home/HomeServices'
import HomeTestimonials from '../components/Home/Testimonials'
import About from '../components/Home/About'
import Contact from '../components/Home/Contact'
import Header from '../components/Header'

const Home = () => {
  return (<div style={{fontFamily:"Gilroy, sans-serif"}} className='overflow-x-hidden'>
    <Header/>
  <Homehero/>
  <HomeServices/>
  <HomeTestimonials/>
  <About/>
  <Contact/>
  </div>)
}

export default Home