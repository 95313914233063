import React from 'react'
import svg1 from "../../assets/Group 427321897.png"
import svg2 from "../../assets/Group 427321896.png"
import svg3 from "../../assets/Group 427321898.png"
import svg4 from "../../assets/Group 427321895.png"

const Whychooseus = () => {
  return (
    <section className="flex flex-col min-h-screen w-screen items-center gap-16 py-16">
        <p className='text-center'>Why choose us</p>
      <p className="text-[#f4f4f4] text-3xl font-bold text-center w-[90%] md:w-[70%] md:text-[70px] md:leading-[73px]">
        Meet Our Teams
      </p>
      <div className="flex justify-around items-center flex-wrap gap-2 gap-y-16 w-[96%]">
        <div className="bg-gradient-to-b from-[#7B7DB7] to-transparent p-[2px] -skew-y-12 mt-20 rounded-xl">
          <div className="w-[280px] h-72 rounded-xl bg-[#1e1c21] flex flex-col items-center gap-4">
            <img
              src={svg1}
              alt=""
              className='p-2'
            />
            <p className="text-white text-center w-[90%] text-[24px] leading-[36px] font-semibold">
            Design team
            </p>
            <p className="text-white text-center w-[90%]">
            Our design team turns imagination into reality. What you think, you will have!
            </p>
          </div>
        </div>
        <div className="bg-gradient-to-b from-[#7B7DB7] to-transparent p-[2px] -skew-y-3 rounded-xl">
          <div className="w-[280px] h-72 rounded-xl bg-[#1E1C21] flex flex-col items-center gap-4">
            <img
              src={svg2}
              alt=""
              className='p-2'
            />
            <p className="text-white text-center w-[90%] text-[24px] leading-[36px] font-semibold">
            Cloud Team
            </p>
            <p className="text-white text-center w-[90%]">ready to take your offline business online and use the cloud skills to connect to your Business From Anywhere.
            </p>
          </div>
        </div>
        <div className="bg-gradient-to-b from-[#7B7DB7] to-transparent p-[2px] skew-y-3 rounded-xl">
          <div className="w-[280px] h-72 rounded-xl bg-[#1E1C21] flex flex-col items-center gap-4">
            <img
              src={svg3}
              alt=""
              className='p-2'
            />
            <p className="text-white text-center w-[90%] text-[24px] leading-[36px] font-semibold">
            AI ML Team
            </p>
            <p className="text-white text-center w-[90%]">
            Want to use the power of AI? With the help of our ML experts, we can build your product with the latest Capabilities.
            </p>
          </div>
        </div>
        <div className="bg-gradient-to-b from-[#7B7DB7] to-transparent p-[2px] skew-y-12 mt-20 rounded-xl">
          <div className="w-[280px] h-72 rounded-xl bg-[#1E1C21] flex flex-col items-center gap-4">
            <img
              src={svg4}
              alt=""
              className='p-2'
            />
            <p className="text-white text-center w-[90%] text-[24px] leading-[36px] font-semibold">
            Developer Team
            </p>
            <p className="text-white text-center w-[90%]">
            Get your things done with the help of the best developers on our team. Bring your ideas into reality.
            </p>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Whychooseus