import React from "react";
import bgImg from "../../assets/jess-bailey-FHCVK6Vyvwc-unsplash.png";

const Expertise = () => {
  return (
    <section
      style={{ backgroundImage: `url(${bgImg})` }}
      className="min-h-screen lg:h-[982px] min-w-[100vw] bg-cover bg-center lg:bg-left-bottom bg-no-repeat"
    >
      <div className="flex">
        <div className="flex flex-col mt-12 ml-4 w-[75%] gap-6 md:gap-16 md:mt-[20vh] md:ml-[12vw] lg:w-[65%] lg:mt-52 lg:ml-36">
          <p className="text-3xl font-bold md:text-[70px] leading-[73px]">Digital guidance expertise</p>
          <p className="md:text-[24px] md:leading-[34px]">
            We are a leading IT consultancy with expertise in innovative
            solutions for modern challenges. Our client-centric approach enables
            us to design tailored solutions that keep businesses ahead in the
            ever-evolving digital landscape.
          </p>
          <button className="w-max px-3 py-1.5 font-semibold bg-white text-black border border-black shadow-[2px_2px_0px_0px] shadow-purple-600 rounded-full text-center">
            Read more
          </button>
        </div>
      </div>
    </section>
  );
};

export default Expertise;
