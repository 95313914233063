import React from "react";
import background from "../../assets/Pexels Photo by Julia Volk.png";

const Testimonials = () => {
  return (
    <section className="relative bg-[#144CC7] overflow-hidden">
      <img
        src={background}
        alt=""
        className="min-w-[100vw] min-h-screen object-cover object-right mix-blend-luminosity"
      />
      <div className="absolute inset-0 bg-transparent flex flex-col">
        <div className="flex lg:flex-col gap-6 md:gap-20 text-[#144CC7] mix-blend-multiply justify-center items-center mt-12 lg:items-start lg:ml-52 lg:mt-40">
          <div className="flex flex-col items-center lg:items-start gap-[15px]">
            <p className="text-3xl md:text-[120px] md:leading-[100px] font-bold">
              200
            </p>
            <p className="text-xl text-center md:text-[40px] md:leading-[48px] font-bold">
              leading businesses
            </p>
          </div>
          <div className="flex flex-col items-center lg:items-start gap-[15px]">
            <p className="text-3xl md:text-[120px] md:leading-[100px] font-bold">
              200
            </p>
            <p className="text-xl text-center md:text-[40px] md:leading-[48px] font-bold">
              leading businesses
            </p>
          </div>
        </div>
        <div className="flex flex-col items-center lg:items-start text-[#144cc7] mix-blend-multiply font-semibold mt-[60vh] mx-3 md:mt-[60vh] md:p-20 md:text-2xl lg:mt-0">
          <p className="text-center lg:text-left">
            Not only do we help businesses reach their goals, but
            <br className="hidden lg:block" /> help build the empire.
            <br className="hidden lg:block" />
            -Satya Nadela
          </p>
        </div>
      </div>
    </section>
  );
};

export default Testimonials;
