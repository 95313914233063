import React from "react";
import theImage from "../../assets/pexels-juan-pablo-serrano-arenas-1250452 1.png";
import overlayimg from "../../assets/02 1.png";
import tape from "../../assets/tape.png";

const About = () => {
  return (
    <section className="bg-[#F43F5E] text-white py-12 lg:py-0">
      <div className="overflow-hidden flex flex-col min-h-screen min-w-[100vw] justify-around items-center gap-8 lg:flex-row lg:justify-between lg:ml-40">
        <div className="flex flex-col gap-6 lg:w-[35%]">
          <div className="flex flex-col">
            <p className="text-3xl md:text-[70px] md:leading-[86.3%] font-bold">
              we are believers,
            </p>
            <p className="text-2xl md:text-[40px] md:leading-[48px] font-bold">
              of the human <span className="italic">touch</span>
            </p>
          </div>
          <p className="hidden text-xl lg:block">
            That’s why, we at hubnex create solutions that matters. We are not
            the beasts hungry for every bit of market, but we value time,
            efforts and emotions as well. We create with the intention of
            sustaining the product in the market for longer than imagined. We
            are believers. We believe in you and your potential to break the
            trend.
          </p>
        </div>
        <div className="relative">
          <img src={theImage} alt="" className=" mix-blend-darken" />
          <img
            src={overlayimg}
            alt=""
            className="absolute top-2  mix-blend-lighten"
          />
          <img
            src={tape}
            alt=""
            className="absolute top-2 right-8  mix-blend-lighten"
          />
        </div>
        <div className="lg:hidden flex justify-center items-center">
          <p className="text-center w-[90%] text-lg">
            That’s why, we at hubnex create solutions that matters. We are not
            the beasts hungry for every bit of market, but we value time,
            efforts and emotions as well. We create with the intention of
            sustaining the product in the market for longer than imagined. We
            are believers. We believe in you and your potential to break the
            trend.
          </p>
        </div>
      </div>
    </section>
  );
};

export default About;
