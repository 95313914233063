import React from "react";

const Testimonial = () => {
  return (
    <section
      className="py-20 text-white flex flex-col items-center p-12"
    >
      <p className="text-center">Testimonial</p>
      <p className="text-[40px] leading-[50px] lg:text-[70px] lg:leading-[85px] text-center font-bold">
        what people say about us
      </p>
      <p className="font-semibold mt-16 text-center md:max-w-[80%] text-lg lg:text-[24px] lg:leading-[29px]">
        Nulla Lorem mollit cupidatat irure. Laborum magna nulla duis ullamco
        cillum dolor. Voluptate exercitation incididunt aliquip deserunt
        reprehenderit elit laborum. Nulla Lorem mollit cupidatat irure. Laborum
        magna nulla duis ullamco cillum dolor. Voluptate exercitation incididunt
        aliquip deserunt reprehenderit elit laborum.
      </p>
      <p className="text-[28px] mt-12 leading-[33.85px] font-bold text-center">
        Rahul Sarkar
      </p>
      <p className="text-xl text-center">UI/UX Designer</p>
    </section>
  );
};

export default Testimonial;
