import React from "react";
import pantera from "../../assets/Pantera Capital - png 0.png";
import pantera2 from "../../assets/Pantera Capital - png 1.png";
import reddit from "../../assets/Reddit - png 0.png";
import tcs from "../../assets/Tata Consultancy Services - png 0.png";
import chorus from "../../assets/Chorus.ai - png 0.png";

import twitter from "../../assets/🦆 icon _twitter_.png";
import linkedin from "../../assets/🦆 icon _linkedin_.png";
import instagram from "../../assets/🦆 icon _instagram_.png";
import facebook from "../../assets/🦆 icon _social facebook_.png";

import user from "../../assets/User.svg";
import building from "../../assets/Buildings.svg";
import email from "../../assets/Envelope.svg";
import phone from "../../assets/PhoneCall.svg";
import msg from "../../assets/ChatCircleDots.svg";
import rightarrow from "../../assets/bx-right-arrow-alt.svg.svg";

const Footer = () => {
  return (
    <section className="bg-transparent backdrop-blur-lg flex flex-col items-center gap-16 pt-16 text-white">
      <p className="text-[60px] text-center leading-[71.58px] font-semibold">
        Our Partners
      </p>
      <div className="flex justify-around items-center flex-wrap gap-y-6 w-[85%]">
        <img src={pantera} alt="" />
        <img src={reddit} alt="" />
        <img src={tcs} alt="" />
        <img src={chorus} alt="" />
        <img src={pantera2} alt="" />
      </div>
      <section className="w-full h-full flex flex-col gap-y-8 items-center overflow-hidden text-white bg-transparent py-12">
        <div className="min-h-screen min-w-[100vw] lg:mt-16 flex flex-col gap-y-40 lg:flex-row justify-start lg:justify-around items-center lg:items-start flex-wrap">
          <div className="w-[90%] lg:w-[30%] flex flex-col gap-6 mt-20 lg:mt-0">
            <p className="text-[70px] leading-[85px] font-extrabold">
              let's talk
            </p>
            <p className="text-[48px] leading-[55px] font-light">
              have some great idea or brand to develop? Let’s build it together!
            </p>
            <p>Our team will reach out to you as soon as possible</p>
          </div>
          <form className="w-[90%] lg:w-[40%] flex-col flex gap-3 bg-[#35324D] p-6 rounded-lg">
            <p className="text-[40px] leading-[59.6px] font-bold py-8 text-center">Let's build it together</p>
            <label
              className="text-white flex items-center gap-2 font-light text-xl"
              htmlFor="name"
            >
              <span>
                <img src={user} alt="" />
              </span>{" "}
              Name *
            </label>
            <input
              type="text"
              name="name"
              id=""
              className="focus:outline-none bg-transparent border-b"
            />
            <label
              className="text-white flex items-center gap-2 font-light text-xl"
              htmlFor="companyName"
            >
              <span>
                <img src={building} alt="" />
              </span>{" "}
              Company Name *
            </label>
            <input
              type="text"
              name="companyName"
              id=""
              className="focus:outline-none bg-transparent border-b"
            />
            <label
              className="text-white flex items-center gap-2 font-light text-xl"
              htmlFor="email"
            >
              <span>
                <img src={email} alt="" />
              </span>{" "}
              Email *
            </label>
            <input
              type="text"
              name="email"
              id=""
              className="focus:outline-none bg-transparent border-b"
            />
            <label
              className="text-white flex items-center gap-2 font-light text-xl"
              htmlFor="phone"
            >
              <span>
                <img src={phone} alt="" />
              </span>{" "}
              Phone *
            </label>
            <input
              type="text"
              name="phone"
              id=""
              className="focus:outline-none bg-transparent border-b"
            />
            <label
              className="text-white flex items-center gap-2 font-light text-xl"
              htmlFor="message"
            >
              <span>
                <img src={msg} alt="" />
              </span>{" "}
              Your Message *
            </label>
            <textarea
              type="text"
              name="message"
              id=""
              className="focus:outline-none bg-transparent border-b"
            />
            <button className="flex items-center p-2 rounded-full bg-transparent text-white border border-white shadow-[2px_2px_0px_0px] shadow-purple-600 text-center w-max">
              SUBMIT{" "}
              <span>
                <img src={rightarrow} alt="" />
              </span>
            </button>
          </form>
        </div>
        <div className="w-80 bg-transparent h-40 flex flex-col items-center gap-y-12">
          <p className="text-xl font-normal">Connect with us</p>
          <div className="flex justify-around gap-6 items-center">
            <button>
              <img src={twitter} alt="" />
            </button>
            <button>
              <img src={linkedin} alt="" />
            </button>
            <button>
              <img src={instagram} alt="" />
            </button>
            <button>
              <img src={facebook} alt="" />
            </button>
          </div>
        </div>
      </section>
      <section className="w-full border-t flex justify-center items-center lg:justify-between py-2 lg:px-10">
        <p>Copyright © 2023 Hubnex Labs. All Rights Reserved</p>
        <div className="hidden lg:flex justify-center items-center flex-wrap">
          <p>About Us</p>
          <p>| Data Protection</p>
          <p>| Terms & Conditions</p>
          <p>| Privacy Policy</p>
          <p>| Contact Us</p>
          <p>| Invest in Us</p>
        </div>
      </section>
    </section>
  );
};

export default Footer;
