import React from "react";
import Heroimg from "../../assets/pexels-sem-steenbergen-2930934.png";

const Homehero = () => {
  return (
    <>
      <div
        style={{ backgroundImage: `url(${Heroimg})` }}
        className="h-screen w-full md:h-[calc(100vh+20rem)] bg-no-repeat bg-cover bg-right-bottom md:bg-cover"
      >
        <div className="w-full top-0 h-screen md:h-[calc(100vh+20rem)] mix-blend-overlay bg-[rgba(156,15,205,0.5)]">
          <div className="top-52 md:top-80 lg:top-60 left-0 pl-10 md:pl-0 absolute md:left-20 z-10 text-white flex flex-col items-start gap-[35px] w-80 md:w-[624px]">
            <p className="text-3xl md:text-[65px] md:leading-[84.63px] font-bold">
              scale your business lead the market
            </p>
            <p className="text-2xl">
              let our team help you reach your{" "}
              <span className="italic font-semibold">dream</span>
            </p>
            <button className="px-3 py-1.5 bg-white text-black rounded-full text-center">
              get started
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default Homehero;
