import React from 'react'
import caret from "../../assets/CaretRight.svg"
import vectorimg from "../../assets/Group 427321849.png"

const Hero = () => {
  return (<section className='min-h-screen h-full py-20 pb-40 w-full flex flex-col justify-center items-center gap-16 bg-transparent backdrop-blur-lg overflow-hidden'>
    <div className="flex flex-col items-center relative mt-16 w-[90%] md:w-[70%] lg:w-[674px] gap-16">
        <p className='font-bold text-3xl md:text-[70px] md:leading-[85px] text-center'>Helping business through technology</p>
        <p className='text-[28px] leading-[149%] text-center'>Revolutionize Your Startup's IT Strategy with Our Consultancy Services</p>
        <button className='bg-[#9772FF] px-3 py-1.5 rounded-full flex w-max'>Get Started <span><img src={caret} alt="" /></span></button>
        <img src={vectorimg} className='absolute -bottom-28 md:-bottom-16 md:left-20 rotate-12 z-30' alt="" />
    </div>
  </section>)
}

export default Hero