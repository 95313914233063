import React from "react";
import vector from "../../assets/Vectorgray.png";

const Whatwedo = () => {
  return (
    <section className="bg-[#0D0D0D] text-white relative overflow-hidden min-h-screen w-full flex flex-col items-center gap-y-6">
      <img src={vector} alt="" className="absolute inset-0 mix-blend-overlay" />
      <p className="text-center text-3xl w-[90%] font-bold md:text-[60px] md:leading-[72px] mt-10">
        What We Do
      </p>
      <p className="text-center w-[90%] font-normal md:text-[24px] md:leading-[27.84px]">
        Delivering exponential value throughout the care ecosystem
      </p>
      <div className="flex justify-around flex-row gap-[32px] w-[90%] mt-8 flex-wrap">
        <div
          style={{
            background: " rgba(255, 255, 255, 0.1)",
            boxShadow: "0px 2px 10px rgba(100, 100, 100, 0.08)",
            backdropFilter: "blur(45px)",
          }}
          className="min-h-[302px] p-2 w-full md:w-[80%] lg:w-[550px] flex flex-col gap-12 rounded-lg"
        >
          <p className="text-[32px] leading-[38px] font-semibold mt-10 lg:ml-6 ml-4">
            Healthcare Provider
          </p>
          <p className="lg:ml-6 lg:w-[80%] ml-4 mb-4">
            Technology led value-driven solutions for patient care. We offer
            innovative and end-to-end integrated solutions for healthcare
            providers. Our solutions enhance the efficiency of physicians,
            hospitals, ancillary facilities, clinics, governmental health bodies
            and public health organizations.
          </p>
        </div>
        <div
          style={{
            background: " rgba(255, 255, 255, 0.1)",
            boxShadow: "0px 2px 10px rgba(100, 100, 100, 0.08)",
            backdropFilter: "blur(45px)",
          }}
          className="min-h-[302px] p-2 w-full md:w-[80%] lg:w-[550px] flex flex-col gap-12 rounded-lg"
        >
          <p className="text-[32px] leading-[38px] font-semibold mt-10 lg:ml-6 ml-4">
            MedTech
          </p>
          <p className="lg:ml-6 lg:w-[80%] ml-4 mb-4">
            Enabling device manufacturers to design and build nexgen healthcare
            technologies. Our MedTech practice is a partner of choice for global
            industry majors. We develop next generation products and
            technologies through strong domain capabilities in end to end
            medical product development solutions.
          </p>
        </div>
        <div
          style={{
            background: " rgba(255, 255, 255, 0.1)",
            boxShadow: "0px 2px 10px rgba(100, 100, 100, 0.08)",
            backdropFilter: "blur(45px)",
          }}
          className="min-h-[302px] p-2 w-full md:w-[80%] lg:w-[550px] flex flex-col gap-12 rounded-lg"
        >
          <p className="text-[32px] leading-[38px] font-semibold mt-10 lg:ml-6 ml-4">
            Pharmaceuticals
          </p>
          <p className="lg:ml-6 lg:w-[80%] ml-4 mb-4">
            Committed to enhance continuum of care for our clients by innovative
            and disruptive technologies. Our deep pharmaceutical domain
            expertise, historical R&D experience, outcome-based partnerships
            with global giants and diverse geographical outreach have enabled
            our clients to achieve seamless digital transformation
          </p>
        </div>
        <div
          style={{
            background: " rgba(255, 255, 255, 0.1)",
            boxShadow: "0px 2px 10px rgba(100, 100, 100, 0.08)",
            backdropFilter: "blur(45px)",
          }}
          className="min-h-[302px] p-2 w-full md:w-[80%] lg:w-[550px] flex flex-col gap-12 rounded-lg"
        >
          <p className="text-[32px] leading-[38px] font-semibold mt-10 lg:ml-6 ml-4">
            Healthcare Payors
          </p>
          <p className="lg:ml-6 lg:w-[80%] ml-4 mb-4">
            Next-generation solutions empowering Payors to improve care quality
            and increase member satisfaction. Our Healthcare Payer Practice has
            been consistently ranked as one of the most matured practices in the
            industry, with over 15 years’ experience in serving several US
            Health Insurers.
          </p>
        </div>
      </div>
    </section>
  );
};

export default Whatwedo;
