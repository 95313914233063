import React from "react";
import Contact from "./Home/Contact";
import pantera from "../assets/Pantera Capital - png 0.png";
import pantera2 from "../assets/Pantera Capital - png 1.png";
import reddit from "../assets/Reddit - png 0.png";
import tcs from "../assets/Tata Consultancy Services - png 0.png";
import chorus from "../assets/Chorus.ai - png 0.png";

const CommonFooter = () => {
  return (
    <section className="bg-[#0D0D0D] flex flex-col items-center gap-16 pt-16 text-white">
      <p className="text-[60px] text-center leading-[71.58px] font-semibold">
        Our Partners
      </p>
      <div className="flex justify-around items-center flex-wrap gap-y-6 w-[85%]">
        <img src={pantera} alt="" />
        <img src={reddit} alt="" />
        <img src={tcs} alt="" />
        <img src={chorus} alt="" />
        <img src={pantera2} alt="" />
      </div>
      <Contact />
      <section className="w-full border-t flex justify-center items-center lg:justify-between py-2 lg:px-10">
        <p>Copyright © 2023 Hubnex Labs. All Rights Reserved</p>
        <div className="hidden lg:flex justify-center items-center flex-wrap">
          <p>About Us</p>
          <p>| Data Protection</p>
          <p>| Terms & Conditions</p>
          <p>| Privacy Policy</p>
          <p>| Contact Us</p>
          <p>| Invest in Us</p>
        </div>
      </section>
    </section>
  );
};

export default CommonFooter;
