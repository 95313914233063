import React from 'react'
import CommonTestimonial from '../components/CommonTestimonial'
import Hero from '../components/Screen4/Hero'
import CommonFooter from "../components/CommonFooter"
import Ourservices from '../components/Screen4/Ourservices'
import Whychooseus from '../components/Screen4/Whychooseus'
import Header from '../components/Header'

const Screen4 = () => {
  return (<div style={{fontFamily:"Gilroy, sans-serif"}}  className='overflow-x-hidden'>
    <Header/>
    <Hero/>
    <Ourservices/>
    <Whychooseus/>
    <CommonTestimonial/>
    <CommonFooter/>
  </div>)
}

export default Screen4