import React from "react";
import twitter from "../../assets/🦆 icon _twitter_.png";
import linkedin from "../../assets/🦆 icon _linkedin_.png";
import instagram from "../../assets/🦆 icon _instagram_.png";
import facebook from "../../assets/🦆 icon _social facebook_.png";
import submitsvg from "../../assets/bx-right-arrow-alt.svg.png";

const Contact = () => {
  return (
    <section style={{fontFamily:"Gilroy, sans-serif"}} className="w-full h-full flex flex-col gap-y-8 items-center overflow-hidden text-white bg-[#0D0D0D] py-12">
      <div className="min-h-screen min-w-[100vw] lg:mt-16 flex flex-col gap-y-40 lg:flex-row justify-start lg:justify-around items-center lg:items-start flex-wrap">
        <div className="w-[90%] lg:w-[30%] flex flex-col gap-6 mt-20 lg:mt-0">
          <p className="text-[70px] leading-[85px] font-extrabold">
            let's talk
          </p>
          <p className="text-[48px] leading-[55px] font-light">
            have some great idea or brand to develop? Let’s build it together!
          </p>
          <p>Our team will reach out to you as soon as possible</p>
        </div>
        <form className="w-[90%] lg:w-[40%] flex-col flex gap-3">
          <label className="text-white font-light text-xl" htmlFor="firstname">FIRST NAME</label>
          <input type="text" name="firstname" id="" className="focus:outline-none bg-transparent border-b" />
          <label className="text-white font-light text-xl" htmlFor="lastname">LAST NAME</label>
          <input type="text" name="lastname" id="" className="focus:outline-none bg-transparent border-b" />
          <label className="text-white font-light text-xl" htmlFor="email">EMAIL *</label>
          <input type="text" name="email" id="" className="focus:outline-none bg-transparent border-b" />
          <label className="text-white font-light text-xl" htmlFor="phone">PHONE</label>
          <input type="text" name="phone" id="" className="focus:outline-none bg-transparent border-b" />
          <label className="text-white font-light text-xl" htmlFor="message">MESSAGE</label>
          <textarea type="text" name="message" id="" className="focus:outline-none bg-transparent border-b" />
          <button className="ml-20"><img src={submitsvg} alt="" /></button>
        </form>
      </div>
      <div className="w-80 bg-transparent h-40 flex flex-col items-center gap-y-12">
        <p className="text-xl font-normal">Connect with us</p>
        <div className="flex justify-around gap-6 items-center">
            <button><img src={twitter} alt="" /></button>
            <button><img src={linkedin} alt="" /></button>
            <button><img src={instagram} alt="" /></button>
            <button><img src={facebook} alt="" /></button>
        </div>
      </div>
    </section>
  );
};

export default Contact;
