import React from 'react'
import Hero from '../components/Screen2/Hero'
import PerformaceAndData from '../components/Screen2/Partners&Data'
import ellipsesvg from "../assets/Ellipse 429.svg"
import line from "../assets/Line 317.png"
import Testimonial from '../components/Screen2/Testimonial'
import Teams from '../components/Screen2/Teams'
import Whychooseus from "../components/Screen2/Whychooseus"
import Footer from '../components/Screen2/Footer'
import Header from '../components/Header'

const Screen2 = () => {
  return (<div style={{fontFamily:"Gilroy, sans-serif"}} className='overflow-hidden relative bg-[#06040A] text-white'>
    <Header/>
    <img src={ellipsesvg} alt="" className='absolute top-4 left-4 blur-2xl' />
    <img src={ellipsesvg} alt="" className='absolute top-[105vh] rotate-180 -right-4 blur-2xl' />
    <img src={ellipsesvg} alt="" className='absolute top-[100vh] rotate-180 -right-12 blur-2xl' />
    <img src={ellipsesvg} alt="" className='absolute top-[220vh] left-4 blur-2xl' />
    <img src={ellipsesvg} alt="" className='absolute top-[320vh] rotate-180 -right-12 blur-2xl' />
    <img src={ellipsesvg} alt="" className='absolute top-[390vh] rotate-180 -right-12 blur-2xl' />
    <img src={ellipsesvg} alt="" className='absolute top-[400vh] -left-12 blur-2xl' />
    <img src={ellipsesvg} alt="" className='absolute top-[580vh] -left-36 blur-2xl' />
    <img src={ellipsesvg} alt="" className='absolute top-[600vh] -left-36 blur-2xl' />
    <Hero/>
    <img src={line} alt="" />
    <PerformaceAndData/>
    <img src={line} alt="" />
    <Testimonial/>
    <img src={line} alt="" />
    <Teams/>
    <img src={line} alt="" />
    <Whychooseus/>
    <img src={line} alt="" />
    <Footer/>
  </div>)
}

export default Screen2