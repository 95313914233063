import React from "react";

const CommonTestimonial = () => {
  return (
    <section
      style={{
        background:
          "linear-gradient(99.53deg, #07010A 0.68%, #321252 37.72%, #49106C 61.51%, #1A0A29 100%)",
      }}
      className="py-20 text-white flex flex-col items-center p-12"
    >
      <p className="text-[40px] leading-[50px] lg:text-[70px] lg:leading-[85px] text-center font-bold">
        what people say about us
      </p>
      <p className="font-semibold mt-16 text-center text-lg lg:text-[24px] lg:leading-[29px]">
        Nulla Lorem mollit cupidatat irure. Laborum magna nulla duis ullamco
        cillum dolor. Voluptate exercitation incididunt aliquip deserunt
        reprehenderit elit laborum. Nulla Lorem mollit cupidatat irure. Laborum
        magna nulla duis ullamco cillum dolor. Voluptate exercitation incididunt
        aliquip deserunt reprehenderit elit laborum.
      </p>
      <p className="text-[28px] mt-12 leading-[33.85px] font-bold text-center">
        Rahul Sarkar
      </p>
      <p className="text-xl text-center">UI/UX Designer</p>
    </section>
  );
};

export default CommonTestimonial;
