import React, { useEffect, useState } from "react";
import logo from "../assets/Frame 12020.png";
import searchIcon from "../assets/icon.svg";
import { Link } from "react-router-dom";

const Header = () => {
  const [blur, setBlur] = useState(false);
  const handleScroll = () => {
    if (window.pageYOffset < 300) {
      setBlur(false);
    } else {
      setBlur(true);
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll, { passive: true });
  }, []);
  return (
    <header
      style={{ zIndex: "300", fontFamily: "Gilroy, sans-serif" }}
      className={
        blur
          ? "transition-all bg-[rgba(0,0,0,0.3)] px-4 md:px-8 duration-300 fixed top-0 h-[114.25px] left-0 right-0 text-white flex justify-between items-center backdrop-blur-lg"
          : "transition-all duration-300 fixed top-0 h-[114.25px] left-4 right-4 lg:left-20 lg:right-20 text-white flex justify-between items-center"
      }
    >
      <Link to="/" className="flex justify-between items-center">
        <img src={logo} alt="" />
        <p className="text-[36px] leading-[36px] font-bold">hubnex labs</p>
      </Link>
      <div className="flex justify-around lg:gap-16 flex-wrap text-lg items-center">
        <Link className="hidden lg:block" to="/screen3">
          About Us
        </Link>
        <Link className="hidden lg:block" to="/screen4">
          Services
        </Link>
        <Link className="hidden lg:block" to="/screen5">
          Industries
        </Link>
        <Link className="hidden lg:block" to="/contact">
          Contact
        </Link>
        <div className="flex items-center gap-6">
          <img src={searchIcon} alt="" className="hidden md:block" />
          <Link
            to="/screen2"
            className="px-3 py-1.5 rounded-full border-white border-2 text-center w-max hover:text-black hover:bg-white"
          >
            Startup Program
          </Link>
        </div>
      </div>
    </header>
  );
};

export default Header;
