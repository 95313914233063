import React from "react";
import webdevicon from "../../assets/costom devp.svg"
import softwareconsult from "../../assets/Communication.svg"
import deploy from "../../assets/gg.svg"
import management from "../../assets/Management.png"
import mobildev from "../../assets/Mobile Development.png"
import grouppp from "../../assets/Group 19075.png"
import apiProgramming from "../../assets/API Programming.png"
import uxdesign from "../../assets/API Programming.png"
import addressMaker from "../../assets/Address marker.png"

const Ourservices = () => {
    return (
    <section className="overflow-hidden flex flex-col items-center gap-12 bg-black text-white">
      <p className="text-center text-3xl font-bold md:text-[70px] md:leading-[87px] mt-12">
        Our Services
      </p>
      <p className="text-center text-xl font-normal max-w-[90%] md:max-w-[70%] lg:max-w-[55%]">
        Our goal is to provide a comprehensive platform that empowers
        individuals and businesses to achieve their full potential. Whether
        you're looking to advance your career, improve your health and wellness,
        or grow your business, our service has something for everyone.
      </p>
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6 w-[96%]">
        <div className="px-5 py-7 rounded-lg flex justify-center items-center bg-[#191919] border border-sky-400">
            <div className="flex flex-col items-start gap-5">
                <div className="flex justify-between w-full mt-2 items-center">
                    <img src={webdevicon} alt="" />
                    <p className="text-[70px] leading-[25px] mix-blend-overlay font-bold">01</p>
                </div>
                <p className="font-semibold text-2xl">Custom Web Development</p>
                <p className="font-normal text-lg">We build customized, intuitive web app solutions that optimize operations, accelerate workflows, boost revenue, and deliver an exceptional product experience.</p>
            </div>
        </div>
        <div className="px-5 py-7 rounded-lg flex justify-center items-center bg-[#191919] border border-sky-400">
            <div className="flex flex-col items-start gap-5">
                <div className="flex justify-between w-full mt-2 items-center">
                    <img src={softwareconsult} alt="" />
                    <p className="text-[70px] leading-[25px] mix-blend-overlay font-bold">02</p>
                </div>
                <p className="font-semibold text-2xl">Software Consulting</p>
                <p className="font-normal text-lg">We analyze business needs and create a roadmap for building a software solution optimized to specific requirements and delivering the best value.</p>
            </div>
        </div>
        <div className="px-5 py-7 rounded-lg flex justify-center items-center bg-[#191919] border border-sky-400">
            <div className="flex flex-col items-start gap-5">
                <div className="flex justify-between w-full mt-2 items-center">
                    <img src={deploy} alt="" />
                    <p className="text-[70px] leading-[25px] mix-blend-overlay font-bold">03</p>
                </div>
                <p className="font-semibold text-2xl">Implementation & Deployment</p>
                <p className="font-normal text-lg">We devise comprehensive and agile deployment & implementation plans using proven methodologies to deliver enhanced and cost-effective solutions to end-users.</p>
            </div>
        </div>
        <div className="px-5 py-7 rounded-lg flex justify-center items-center bg-[#191919] border border-sky-400">
            <div className="flex flex-col items-start gap-5">
                <div className="flex justify-between w-full mt-2 items-center">
                    <img src={management} alt="" />
                    <p className="text-[70px] leading-[25px] mix-blend-overlay font-bold">04</p>
                </div>
                <p className="font-semibold text-2xl">Maintenance & Management</p>
                <p className="font-normal text-lg">Our app maintenance and management services ensure that your software infrastructure stays relevant, scalable, sustainable, and high-performing.</p>
            </div>
        </div>
        <div className="px-5 py-7 rounded-lg flex justify-center items-center bg-[#191919] border border-sky-400">
            <div className="flex flex-col items-start gap-5">
                <div className="flex justify-between w-full mt-2 items-center">
                    <img src={mobildev} alt="" />
                    <p className="text-[70px] leading-[25px] mix-blend-overlay font-bold">05</p>
                </div>
                <p className="font-semibold text-2xl">Mobile App Development</p>
                <p className="font-normal text-lg">Our robust & customizable frameworks help develop smart mobile apps that analyze user data and incorporate AI ML to deliver personalized user experiences</p>
            </div>
        </div>
        <div className="px-5 py-7 rounded-lg flex justify-center items-center bg-[#191919] border border-sky-400">
            <div className="flex flex-col items-start gap-5">
                <div className="flex justify-between w-full mt-2 items-center">
                    <img src={grouppp} alt="" />
                    <p className="text-[70px] leading-[25px] mix-blend-overlay font-bold">06</p>
                </div>
                <p className="font-semibold text-2xl">Custom Application Development</p>
                <p className="font-normal text-lg">Leverage our deep domain expertise to develop robust, scalable & interoperable mobile, web, desktop & hybrid applications that deliver optimal business outcomes.</p>
            </div>
        </div>
        <div className="px-5 py-7 rounded-lg flex justify-center items-center bg-[#191919] border border-sky-400">
            <div className="flex flex-col items-start gap-5">
                <div className="flex justify-between w-full mt-2 items-center">
                    <img src={apiProgramming} alt="" />
                    <p className="text-[70px] leading-[25px] mix-blend-overlay font-bold">07</p>
                </div>
                <p className="font-semibold text-2xl">API Development & Integration</p>
                <p className="font-normal text-lg">Develop reliable and easy to consume custom APIs that seamlessly integrate custom software with enterprise and third-party systems & services to maximize uptime & scalability</p>
            </div>
        </div>
        <div className="px-5 py-7 rounded-lg flex justify-center items-center bg-[#191919] border border-sky-400">
            <div className="flex flex-col items-start gap-5">
                <div className="flex justify-between w-full mt-2 items-center">
                    <img src={uxdesign} alt="" />
                    <p className="text-[70px] leading-[25px] mix-blend-overlay font-bold">08</p>
                </div>
                <p className="font-semibold text-2xl">Custom UX/UI Design</p>
                <p className="font-normal text-lg">Leverage the latest UX/UI technologies, architectures, and trends to design responsive & scalable user-centric apps that transform user experiences across channels.</p>
            </div>
        </div>
        <div className="px-5 py-7 rounded-lg flex justify-center items-center bg-[#191919] border border-sky-400">
            <div className="flex flex-col items-start gap-5">
                <div className="flex justify-between w-full mt-2 items-center">
                    <img src={addressMaker} alt="" />
                    <p className="text-[70px] leading-[25px] mix-blend-overlay font-bold">09</p>
                </div>
                <p className="font-semibold text-2xl">System Integration</p>
                <p className="font-normal text-lg">Our software integration engineers adopt next-gen technologies and processes to streamline and integrate everything from design, development, and testing to execution.</p>
            </div>
        </div>
      </div>
    </section>
  );
};

export default Ourservices;
