import React from 'react'
import svg1 from "../../assets/Our-Mission.png.svg"
import svg2 from "../../assets/Our-Philosophy.png.svg"
import svg3 from "../../assets/Our-Vision.png.svg"
import svg4 from "../../assets/Our-Strategy.png.svg"

const Teams = () => {
  return (
    <section className="flex flex-col min-h-screen w-screen items-center gap-16 py-16">
        <p className='text-center'>Teams</p>
      <p className="text-[#f4f4f4] text-3xl font-bold text-center w-[90%] md:w-[70%] md:text-[70px] md:leading-[73px]">
        Your success is our top priority -always.
      </p>
      <p className="text-white text-center w-[90%] md:w-[80%] lg:w-[65%]">
        At our IT consultancy firm, we prioritize certain values and approaches
        that have helped us build a reputation for excellence in the industry.
        First and foremost, we prioritize customer satisfaction, and we always
        go the extra mile to ensure our clients' needs are met.
      </p>
      <div className="flex justify-around items-center flex-wrap gap-2 gap-y-16 w-[96%]">
        <div className="bg-gradient-to-b from-[#7B7DB7] to-transparent p-[2px] rounded-xl">
          <div className="w-[280px] h-72 rounded-xl bg-[#1e1c21] flex flex-col items-center gap-4">
            <img
              src={svg1}
              alt=""
              className="bg-[#9495C4] p-3 rounded-full mt-6"
            />
            <p className="text-white text-center w-[90%] text-[24px] leading-[36px] font-semibold">
              Our Mission
            </p>
            <p className="text-white text-center w-[90%]">
              Use the power of technology and our deep understanding of the
              industry to solve engineering challenges of any nature, scale, or
              complexity
            </p>
          </div>
        </div>
        <div className="bg-gradient-to-b from-[#7B7DB7] to-transparent p-[2px] rounded-xl">
          <div className="w-[280px] h-72 rounded-xl bg-[#1E1C21] flex flex-col items-center gap-4">
            <img
              src={svg2}
              alt=""
              className="bg-[#9495C4] p-3 rounded-full mt-6"
            />
            <p className="text-white text-center w-[90%] text-[24px] leading-[36px] font-semibold">
              Our Philosophy
            </p>
            <p className="text-white text-center w-[90%]">
              Go above and beyond to bring in new perspectives, relentless
              energy, and utmost dedication to driving client success
            </p>
          </div>
        </div>
        <div className="bg-gradient-to-b from-[#7B7DB7] to-transparent p-[2px] rounded-xl">
          <div className="w-[280px] h-72 rounded-xl bg-[#1E1C21] flex flex-col items-center gap-4">
            <img
              src={svg3}
              alt=""
              className="bg-[#9495C4] p-3 rounded-full mt-6"
            />
            <p className="text-white text-center w-[90%] text-[24px] leading-[36px] font-semibold">
              Our Vission
            </p>
            <p className="text-white text-center w-[90%]">
              Harness innovation to accelerate digital transformation and drive
              change & client success
            </p>
          </div>
        </div>
        <div className="bg-gradient-to-b from-[#7B7DB7] to-transparent p-[2px] rounded-xl">
          <div className="w-[280px] h-72 rounded-xl bg-[#1E1C21] flex flex-col items-center gap-4">
            <img
              src={svg4}
              alt=""
              className="bg-[#9495C4] p-3 rounded-full mt-6"
            />
            <p className="text-white text-center w-[90%] text-[24px] leading-[36px] font-semibold">
              Our Strategy
            </p>
            <p className="text-white text-center w-[90%]">
              Leverage next-gen technologies, robust internal frameworks, and
              defined processes to provide best-in-class services within
              timelines
            </p>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Teams